import { VENDOR_SEARCH_INTERACTION } from 'apps/marketplace-web/client/utils/tracking';

export default {
	ADD_TO_FAVORITE: 'Add to Favorite',
	BREADCRUMB_INTERACTION: 'Breadcrumb Interaction',
	CALL_VENDOR_INITIATED: 'Call Vendor Initiated',
	CALL_VENDOR: 'Call Vendor',
	CATEGORY_RESULTS: 'category results',
	SEMANTIC_RESULTS: 'semantic_results',
	CHANGE_LOCATION: 'change location',
	CLICK_THROUGH_TO_CONVERSATIONS: 'Click-through to Conversations',
	CLICK_THROUGH_TO_DESIGNER: 'Click-through to Designer',
	CLICK_THROUGH_TO_VENDOR_LIST: 'Click-through to Vendor List', // links to user's favorites
	DISMISS: 'dismiss',
	VENDOR_CATEGORY_SELECTION: 'Vendor Category Selection',
	VENDOR_CATEGORY_VIEWED: 'Vendor Category Viewed',
	CLICK_THROUGH_TO_VENDOR_CATEGORY: 'Click-through to Vendor Category',
	CLICK_THROUGH_TO_VENDORS: 'Click-through to Vendors', // links to an individual vendor
	CLICK_THROUGH_TO_WEBSITE: 'Click-through to Website',
	CLICKED_RIGHT: 'clicked right',
	CLICKED_LEFT: 'clicked left',
	CLICKED_OVERVIEW_PHOTO: 'overview photo clicked',
	FAVORITED_VENDOR: 'favorited vendor',
	FILTER_VENDORS: 'Filter Vendors',
	FILTER_CLICKED: 'filter clicked',
	SWIPE_RIGHT: 'swipe right',
	SWIPE_LEFT: 'swipe left',
	QUOTE_REQUESTED: 'quote requested',
	CLICK_MOSAIC: 'click mosaic',
	MARKETPLACE_HOME_VIEWED: 'Marketplace Home viewed',
	MEDIA_OVERVIEW: 'media overview',
	MOSAIC: 'mosaic',
	MOSAIC_CTA: 'mosaic CTA',
	MOSAIC_TILE: 'mosaic tile',
	OVERVIEW_VIEWED: 'overview viewed',
	OVERVIEW_PHOTO_CLICKED: 'overview photo clicked',
	MINI_NAV_INTERACTION: 'Mini Nav Interaction',
	NEXT: 'next',
	PAID_STOREFRONT: 'paid storefront',
	PASSWORD_CREATE: 'password create',
	PASSWORD_UPSELL: 'password upsell',
	PHOTO_GALLERY: 'photo gallery',
	PHOTO_DETAIL_VIEW: 'photo detail view',
	PLAY_TOUR: 'play tour',
	PLAY_VIDEO: 'play video',
	POST_LEAD_INTERACTION: 'Post Lead Interaction',
	REVIEW_VENDOR: 'Review Vendor',
	SAVE_PASSWORD: 'save password',
	CLICK_SAVE_PASSWORD: 'click save password',
	SEARCH: 'search',
	SEE_ALL_IMAGES: 'see all images',
	SEE_MORE_REVIEWS: 'See More Reviews',
	SHARE_STOREFRONT: 'Share Storefront',
	SORT_VENDORS: 'Sort Vendors',
	STOREFRONT_VIEWED_VTR: 'Storefront Viewed - VTR',
	STOREFRONT: 'storefront',
	TOUR_INTERACTION: '360 Tour Interaction',
	UNPAID_STOREFRONT: 'unpaid storefront',
	VENDOR_HOME_INTERACTION: 'Vendor Home Interaction',
	VENDOR_PORTFOLIO: 'vendor portfolio',
	VENDOR_PORTFOLIO_INTERACTION: 'Vendor Portfolio Interaction',
	VENDOR_REVIEW_INITIATED: 'Vendor Review Initiated',
	VENDOR_TEXT_SEARCH_START: 'Vendor Text Search Start',
	WIDGET_VIEWED: 'Widget Viewed',
	VENDOR_NETWORK_VIEWED: 'Vendor Network Impression',
	VENDOR_NETWORK_INTERACTION: 'Vendor Network Interaction',

	SEE_VIDEOS: 'see videos',
	SEE_VIRTUAL_TOUR: 'see virtual tour',

	REVIEW_HUB: 'review hub',
	REVIEW_INTERACTION: 'Review Interaction',
	REVIEWS_IMPRESSION: 'Reviews Impression',
	READ_MORE: 'read more',
	READ_LESS: 'read less',

	sharedAnalyticsProps: { product: 'marketplace' },
	sharedMetadata: { applicationName: 'marketplace' },
	portfolioType: {
		MAIN: 'Main',
		REAL_WEDDING: 'Real Wedding',
		REVIEWS: 'Reviews',
		ABOUT_VENDOR_VIDEO: 'About vendor video',
	},
	widgetImpressionType: {
		STOREFRONT_RIGHT_RAIL: 'Rec Module: Storefront - Right Rail',
		STOREFRONT_CENTER: 'Rec Module: Storefront - Center',
		STOREFRONT_BOTTOM: 'Rec Module: Storefront - Bottom',
		STOREFRONT_TOP: 'Rec Module: Storefront - Top',
		DIRECTORY_RIGHT_RAIL: 'Rec Module: Directory - Right Rail Recs',
	},
	LINK_CLICKED: 'Link Clicked',
	BUTTON_CLICKED: 'Button Clicked',

	WIDGET_CLICKED: 'Widget Clicked',

	VENDOR_SEARCH_INTERACTION: 'Vendor Search Interaction',
};
