import honeybadger from '@honeybadger-io/js';
import { setContentAndStatus } from '@redux/alert/actionCreators';
import { addConversation } from '@redux/messagedVendors';
import { identityApi, inquireAPIUrl, tkHost } from '@settings';
import { isFromSearchPage } from '@utils/regex';
import { getMember } from '@xo-union/store-membership-redux';
import axios from 'axios';
import request from 'superagent';
import type { InlineRfqSourceContent } from 'types/sourceContent';
import {
	setAccountCreationStatus,
	setChangePasswordStatus,
	setRegistrationStatus,
	updateAreErrorsInForm,
	updateLastVendorNameSent,
} from './actionCreators';
import {
	PasswordSessionStorageKeyName,
	buildInquiryData,
	changeUserPassword,
	determineFieldValues,
	fetchTemporaryPassword,
	generateTemporaryPassword,
	getTokenForUser,
	logInUser,
	signUpUser,
	updateLocalStorage,
	updateMember,
	updateWeddings,
	upsertWeddingForUser,
} from './utils';

export const checkErrorsInForm =
	(): Redux.ThunkResult<Promise<void>> => async (dispatch, getState) => {
		const state = getState();

		const {
			emailAddress,
			firstName,
			guestCount,
			lastName,
			textarea,
			weddingDate,
		} = state.rfq.inline.fields;
		const areErrors = [
			emailAddress,
			firstName,
			guestCount,
			lastName,
			textarea,
			weddingDate,
		].some((field) => field.error !== '');
		dispatch(updateAreErrorsInForm(areErrors));
	};

export const checkIsUserRegistered =
	(type?: string): Redux.ThunkResult<Promise<void>> =>
	async (dispatch, getState) => {
		const state = getState();
		const {
			membership: { member },
			rfq: { inline, rfqFormData },
		} = state;

		if (member) {
			dispatch(setRegistrationStatus(true));
			return;
		}

		const email =
			type === 'inline'
				? inline.fields.emailAddress.value
				: rfqFormData.emailAddress;

		try {
			const GET_IDENTITY_URL = `${identityApi}/member/exists?email=${email}`;
			const result = await axios.get<boolean>(GET_IDENTITY_URL);
			const isUserRegistered = result.data;
			dispatch(setRegistrationStatus(isUserRegistered));
		} catch (error) {
			dispatch(setRegistrationStatus(false));
			honeybadger.notify('Identity API call failed', error);
		}
	};

export const registerUser =
	(type?: string): Redux.ThunkResult<Promise<boolean>> =>
	async (dispatch, getState, membershipService?: MembershipService.Service) => {
		const state = getState();
		const { isUserRegistered } = state.rfq;
		const { emailAddress, firstName, lastName, weddingDate } =
			determineFieldValues(state, type);
		const temporaryPassword = generateTemporaryPassword();

		const signUpDetails: MembershipService.SignUpParameters = {
			action: 'lead-autocreate',
			application: 'marketplace',
			brand: 'theknot',
			email: emailAddress,
			first_name: firstName,
			last_name: lastName,
			password: temporaryPassword,
		};

		if (isUserRegistered) {
			return true;
		}
		if (!membershipService) {
			return false;
		}

		try {
			const response = await signUpUser({ signUpDetails, membershipService });
			const memberUuid = response.body.members.id;

			const token = await getTokenForUser(membershipService);
			await upsertWeddingForUser({ memberUuid, token, weddingDate });

			dispatch(setAccountCreationStatus('success'));
			dispatch({
				type: 'middleware/SET_SESSION_STORAGE',
				payload: {
					key: PasswordSessionStorageKeyName,
					value: temporaryPassword,
				},
			});
			return true;
		} catch (error) {
			dispatch(setAccountCreationStatus('errored'));
			honeybadger.notify('Auto account creation sign-up failed', error);
			return false;
		}
	};

const buildInquiry = (data: Rfq.InquiryData) =>
	request
		.post(inquireAPIUrl)
		.send(data)
		.then((response) => ({ response: response.body, inquiry: data }));

const buildFormData = (fields: Rfq.InlineFields): Rfq.FormData => {
	const {
		emailAddress,
		guestCount,
		firstName,
		lastName,
		phoneNumber,
		textarea,
		weddingDate,
	} = fields;
	const [minGuestCount, maxGuestCount] = guestCount.value.split('-');

	return {
		body: textarea.value,
		emailAddress: emailAddress.value,
		firstName: firstName.value,
		guest_count: {
			display: guestCount.value,
			value: {
				minGuestCount: Number(minGuestCount),
				maxGuestCount: Number(maxGuestCount),
			},
		},
		lastName: lastName.value,
		phoneNumber: phoneNumber.value,
		xo_date_picker: { weddingDate: weddingDate.value },
	};
};

export const sendInquiryLead =
	(
		initiator: InlineRfqSourceContent,
	): Redux.ThunkResult<Promise<Promise<string | undefined>>> =>
	async (dispatch, getState) => {
		const state = getState();
		const {
			category: { code },
			membership,
			requestInfo: {
				info: { referrer },
			},
			rfq: {
				inline: { fields, isDateFlexible },
			},
			settings: { anonymousId, marketCode, isReferred },
			vendor: { vendor, vendorRaw },
			vrm: { similarVendors },
		} = state;
		const formData = buildFormData(fields);

		await dispatch(checkIsUserRegistered('inline'));
		const accountCreated = await dispatch(registerUser('inline'));
		if (!accountCreated) {
			return Promise.resolve(undefined);
		}
		await dispatch(handleAutoLogIn('inline'));
		if (vendor) {
			dispatch(updateLastVendorNameSent(vendor.name));
		}

		const inquiryData = buildInquiryData({
			anonymousId,
			fields,
			flexibleWeddingDate: isDateFlexible,
			isReferred,
			isFromSearch: isFromSearchPage(referrer),
			marketCode,
			vendor,
			member: membership.member,
			initiator,
		});
		const Inquiry = buildInquiry(inquiryData);

		return Inquiry.then((data) => {
			const { inquiry, response } = data;

			Promise.all([
				updateMember(inquiry, membership.member),
				updateLocalStorage(
					{ ...formData, flexibleWeddingDate: isDateFlexible },
					code,
				),
				updateWeddings(formData, code, membership.member),
			]).catch((error) => new Error(`${error}`));

			return response;
		})
			.then((response) => {
				const { storefrontId, conversationId } = response;
				dispatch(addConversation({ [storefrontId]: conversationId }));

				return response.conversationId;
			})
			.catch((error) => {
				throw new Error(`Inquiry post failed with ${error}`);
			});
	};

export const handleAutoLogIn =
	(type?: string): Redux.ThunkResult<Promise<void>> =>
	async (dispatch, getState, membershipService?: MembershipService.Service) => {
		const state = getState();
		const { creationSuccessful, inline, rfqFormData } = state.rfq;
		const { member } = state.membership;
		const emailAddress =
			type === 'inline'
				? inline.fields.emailAddress.value
				: (rfqFormData as MembershipService.RequiredData).emailAddress;

		const logInDetails = {
			email: emailAddress,
			password: fetchTemporaryPassword(),
		};

		if (
			member ||
			creationSuccessful !== 'success' ||
			logInDetails.password === null ||
			!membershipService
		) {
			return;
		}

		try {
			await logInUser({
				logInDetails: {
					email: logInDetails.email,
					password: logInDetails.password,
				},
				membershipService,
			});
			dispatch(getMember());
		} catch (error) {
			honeybadger.notify('Failed to automatically login user.', error);
		}
	};

export const changePassword =
	(
		emailAddress: string,
		newPassword: string,
	): Redux.ThunkResult<Promise<void>> =>
	async (dispatch, getState, membershipService?: MembershipService.Service) => {
		const changePasswordDetails = {
			email: emailAddress,
			password: fetchTemporaryPassword(),
			new_password: newPassword,
			new_password_confirmation: newPassword,
		};

		const CHANGE_PASSWORD_ERROR_MESSAGE =
			"We're having trouble saving your password. Please try again.";

		if (changePasswordDetails.password === null || !membershipService) {
			dispatch(
				setChangePasswordStatus('errored', CHANGE_PASSWORD_ERROR_MESSAGE),
			);
			return;
		}

		try {
			await changeUserPassword({
				changePasswordDetails: {
					email: changePasswordDetails.email,
					password: changePasswordDetails.password,
					new_password: changePasswordDetails.new_password,
					new_password_confirmation:
						changePasswordDetails.new_password_confirmation,
				},
				membershipService,
			});

			const link: Alert.Link = {
				href: `${tkHost}/account/settings/account-management`,
				linkText: 'account settings.',
			};

			dispatch(
				setChangePasswordStatus('success', 'Password saved successfully.'),
			);
			dispatch(
				setContentAndStatus(
					'Password saved! Change it anytime in your',
					true,
					link,
				),
			);
			if (window?.sessionStorage) {
				window.sessionStorage.removeItem(PasswordSessionStorageKeyName);
				window.dispatchEvent(
					new StorageEvent(`sessionStorage-${PasswordSessionStorageKeyName}`, {
						key: PasswordSessionStorageKeyName,
						newValue: null,
					}),
				);
			}
		} catch (error) {
			dispatch(
				setChangePasswordStatus('errored', CHANGE_PASSWORD_ERROR_MESSAGE),
			);
			honeybadger.notify(
				"Failed to update user's password with membership SDK",
				error,
			);
		}
	};
