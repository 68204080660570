import { useAppSelector } from '@redux/hooks';
import { Decorated } from '@typings/vendor';
import { useMemo } from 'react';
import { FEATURE_FLAGS, useFeatureFlags } from '../../../feature-flags';

export const useStorefrontFeatureScannability = () => {
	const featureFlags = useFeatureFlags();
	const vendor = useAppSelector((state) => state.vendor.vendor as Decorated);
	const shouldShowScannability = useMemo(() => {
		return featureFlags[FEATURE_FLAGS.STOREFRONT_SCANNABILITY] && vendor.isPaid;
	}, [featureFlags[FEATURE_FLAGS.STOREFRONT_SCANNABILITY]]);
	return shouldShowScannability;
};
