import { useAppSelector } from '@redux/hooks';
import { useAtomValue } from 'jotai';
import { State } from 'types/redux';
import { hasStartingCostAtom } from '../jotai/storefront/detailed-pricing';

export const categoryCodes = ['WPH'];

export const useDetailedPricing = () => {
	const categoryCode = useAppSelector(
		(state: State) => state.vendor.vendor?.categoryCode,
	);
	const hasStartingCost = useAtomValue(hasStartingCostAtom);

	return Boolean(
		categoryCode && categoryCodes.includes(categoryCode) && hasStartingCost,
	);
};
