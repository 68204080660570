import { selectAssignment } from '@redux/experiments/selectors/index';
import { SemanticSearchAssignment } from '@typings/experiments';
import { State } from 'types/redux';

export const semanticSearchAssignmentSelector = (state: State) => {
	if (state.category.code !== 'REC') {
		return null;
	}

	return selectAssignment(state, [
		'semanticSearch',
	]) as SemanticSearchAssignment;
};

export const isInSemanticSearchSelector = (state: State) => {
	const assignment = semanticSearchAssignmentSelector(state);
	return assignment === 'semantic-search';
};
